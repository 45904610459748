.advocate-form {
    margin-bottom: 3.2rem;
    text-align: left;

    &:last-child {
        margin-bottom: 2rem;
    }

    &-row {
        display: block;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0 !important;
        }

        &__label {
            font-size: 1.4rem;
            text-align: left;
            font-style: normal;
            letter-spacing: normal;
            margin-bottom: .8rem;
        }

        &__content {
            position: relative;
            cursor: pointer;
        }

        &-group {
            display: block;
            position: relative;

            &--separated {
                border-bottom: 10px $color-grey-dark solid;
                margin-bottom: .5rem;
                padding-bottom: .5rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-subrow {
        display: flex;
        flex-direction: row;

        &--heading {
            align-items: center;
            margin-bottom: .625rem;
        }

        &--separated {
            border-bottom: 1px $color-grey-dark;
            padding-bottom: .5rem;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }

        &--spaced {
            justify-content: space-between;
        }

        &__col {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 .25rem;


            &:not(:last-child) {
                margin-right: 3.2rem;
            }

            &--stretched {
                flex: 1;
            }
        }

        &__label {
            display: block;
            font-size: 1.4rem;
            margin-bottom: .8rem;

        }
    }

    .cux-textbox {
        display: block;
        height: 5.5rem;
        width: 100%;
        padding-left: 2rem;
        border: 1px solid $color-border;

        &--inline {
            display: inline;
            width: auto;
        }
    }
}

.advocate-form-label {
    display: inline-flex;

    flex-direction: row;

    &:last-child {
        margin-right: 0;
    }
}

.advocate-help-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
    margin-top: .6rem;
    margin-bottom: -1rem;

    &--icon {
        display: flex;
        align-items: center;
        margin-top: -.8rem;
    }

    // &--success {
    //     color: map-get($map: $brandColors, $key: green-success);
    // }

    // &--info {
    //     color: map-get($map: $brandColors, $key: blue);
    // }

    &--error {
        color: $color-red;
    }

    // &--muted {
    //     color: map-get($map: $brandColors, $key: grey-mute);
    // }
}

.advocate-row-chips {
    margin-top: 1rem;
}

.advocate-required-field::after {
    content: " *";
}