.cux-icon {
    height: 2.4rem;
    width: 2.4rem;
    font-size: 2.4rem;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    &--extra-small {
        height: 1.8rem;
        width: 1.8rem;
        font-size: 1.8rem;
    }

    &--medium {
        height: 3.2rem;
        width: 3.2rem;
        font-size: 3.2rem;
    }

    &--large {
        height: 4rem;
        width: 4rem;
        font-size: 4rem;
    }

    &--primary {
        color: $color-primary;
    }

    &--success {
        color: $color-primary;
    }

    &--info {
        // color: map-get($map: $brandColors, $key: blue);
    }

    &--danger {
        color: $color-red;
    }

    &--muted {
        color: $color-muted;
    }

    &--white {
        color: $color-white;
    }
}