.breadcrumbs {
    margin-top: 3rem;
    display: flex;
    gap: 1rem;

    &--active {
        color: $color-font;
    }

    &--in-active {
        cursor: pointer;
        color: $color-primary;
    }
}