$inputBorder: 2px solid $color-border;
$borderRadius: .4rem;
$inputTextColor: $color-font;

$inputFontSize: 1.4rem;
$inputLineHeight: 2.8rem;
$inputPadding: .5rem;

$inputPhTextColor: $color-font-placeholder;

$inputFocusColor: $color-primary;
$inputShadow: inset 0 0 .3125rem;

.cux-textbox {
    appearance: none;
    background: $color-white;
    border: $inputBorder;
    border-radius: $borderRadius;
    box-sizing: border-box;
    color: $inputTextColor;
    display: inline-block;
    font-size: $inputFontSize;
    line-height: $inputLineHeight;
    margin: 0;
    padding-left: 1.6rem;
    height: 4.8rem;

    &::placeholder {
        color: $inputPhTextColor;
        font-size: $inputFontSize;
        line-height: $inputLineHeight;
        overflow: visible; // prevents text from being cliped at the bottom
    }

    &:focus {
        border: 2px solid $inputFocusColor;
        box-shadow: $inputShadow rgba($inputFocusColor, .25);
        outline: none;
    }

    &--stretched {
        width: 100%;
    }

    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 4rem;
        width: 4rem;
        cursor: pointer;
        padding-right: 1.2rem;

        &--primary {
            color: $color-primary;
        }
    }

    &__fileName {
        display: flex;
        justify-content: flex-start;
    }

    &--error {
        border: 2px solid $color-red !important;
    }

    &--success {
        border: 2px solid $color-primary !important;
    }
}